import { keycloak } from 'client/keycloak/instance'
import Layout from 'components/Layout'
import {
	Accounting,
	ApplicationPage,
	ApplicationsPage,
	Assessment,
	CalculatorPage,
	CreatePersonPage,
	Delivery,
	Deposit,
	EntrepreneurPage,
	FacilitiesPage,
	FacilityPage,
	Home,
	LeadInvestment,
	LeadPage,
	LeadsPage,
	LoanPage,
	LoanSellApplication,
	LoansPage,
	NewLeadPage,
	OrganizationPage,
	ParticipantsPage,
	Partner,
	PersonPage,
	Representative,
	RosreestrRegistration,
	YandexDirect,
} from 'components/pages'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import url from './urls'

const authWrapperConfig = {
	authenticatedSelector: () => keycloak.authenticated,
	wrapperDisplayName: 'privateOrPublicRoute',
}

const privateOrPublicRoute = () => connectedAuthWrapper(authWrapperConfig)(Layout)

export const routes = [
	{
		component: privateOrPublicRoute(),
		routes: [
			{
				...url.home,
				component: Home,
			},
			{
				...url.leads,
				title: 'Лиды',
				component: LeadsPage,
			},
			{
				...url.investmentLead.list,
				title: 'Инвестиционные лиды',
				component: LeadInvestment.List,
			},
			{
				title: 'Инвестиционный лид',
				component: LeadInvestment.Single,
				path: url.investmentLead.single.path(),
				exact: url.investmentLead.single.exact,
			},
			{
				title: 'Лид',
				component: LeadPage,
				path: url.lead.path(),
				exact: url.lead.exact,
			},
			{
				...url.newLead,
				title: 'Новый лид',
				component: NewLeadPage,
			},
			{
				...url.applications,
				title: 'Заявки',
				component: ApplicationsPage,
			},
			{
				title: 'Заявка',
				component: ApplicationPage,
				path: url.application.path(),
				exact: url.application.exact,
			},
			{
				...url.loans,
				title: 'Займы',
				component: LoansPage,
			},
			{
				title: 'Займ',
				component: LoanPage,
				path: url.loan.path(),
				exact: url.loan.exact,
			},
			{
				title: 'Регистрация',
				component: RosreestrRegistration.List,
				path: url.rosreestrRegistration.list.path,
				exact: url.rosreestrRegistration.list.exact,
			},
			{
				title: 'Регистрация',
				component: RosreestrRegistration.Single,
				path: url.rosreestrRegistration.single.path(),
				exact: url.rosreestrRegistration.single.exact,
			},
			{
				title: 'Партнёры',
				component: Partner.List,
				path: url.partner.list.path,
				exact: url.partner.list.exact,
			},
			{
				title: 'Партнёры',
				component: Partner.Single,
				path: url.partner.single.path(),
				exact: url.partner.single.exact,
			},
			{
				title: 'Новая доставка',
				component: Delivery.Create,
				path: url.delivery.create.path,
				exact: url.delivery.create.exact,
			},
			{
				title: 'Доставки',
				component: Delivery.List,
				path: url.delivery.list.path,
				exact: url.delivery.list.exact,
			},
			{
				title: 'Доставка',
				component: Delivery.Single,
				path: url.delivery.single.path(),
				exact: url.delivery.single.exact,
			},
			{
				...url.participants,
				title: 'Участники',
				component: ParticipantsPage,
			},
			{
				...url.participantCreate,
				title: 'Создание',
				component: CreatePersonPage,
			},
			{
				title: 'Физ. лицо',
				component: PersonPage,
				path: url.personOut.path(),
				exact: url.personOut.exact,
			},
			{
				title: 'Физ. лицо',
				component: PersonPage,
				path: url.person.path(),
				exact: url.person.exact,
			},
			{
				title: 'Юр. лицо',
				component: OrganizationPage,
				path: url.organizationOut.path(),
				exact: url.organizationOut.exact,
			},
			{
				title: 'Юр. лицо',
				component: OrganizationPage,
				path: url.organization.path(),
				exact: url.entrepreneur.exact,
			},
			{
				title: 'ИП',
				component: EntrepreneurPage,
				path: url.entrepreneurOut.path(),
				exact: url.entrepreneurOut.exact,
			},
			{
				title: 'ИП',
				component: EntrepreneurPage,
				path: url.entrepreneur.path(),
				exact: url.entrepreneur.exact,
			},
			{
				title: 'Обьект',
				component: FacilityPage,
				path: url.facilityOut.path(),
				exact: url.facilityOut.exact,
			},
			{
				...url.facilities,
				title: 'Объекты',
				component: FacilitiesPage,
			},
			{
				title: 'Объект',
				component: FacilityPage,
				path: url.facility.path(),
				exact: url.facility.exact,
			},
			{
				...url.assessment,
				title: 'Оценка',
				component: Assessment,
			},
			{
				...url.calculator,
				title: 'Калькулятор',
				component: CalculatorPage,
			},
			{
				path: url.deposit.single.path(),
				exact: url.deposit.single.exact,
				title: 'Сбережение',
				component: Deposit.Single,
			},
			{
				...url.deposit.list,
				title: 'Сбережения',
				component: Deposit.List,
			},
			{
				...url.deposit.create,
				title: 'Новое сбережение',
				component: Deposit.Create,
			},
			{
				...url.yandex.direct,
				title: 'Яндекс.Директ',
				component: YandexDirect,
			},
			{
				...url.representative.list,
				title: 'Представители',
				component: Representative.List,
			},
			{
				path: url.representative.single.path(),
				exact: url.representative.single.exact,
				title: 'Представитель',
				component: Representative.Single,
			},
			{
				...url.representative.create,
				title: 'Создать',
				component: Representative.Create,
			},
			{
				...url.loanSellApplications.list,
				title: 'заявки',
				component: LoanSellApplication.List,
			},
			{
				path: url.loanSellApplications.single.path(),
				exact: url.loanSellApplications.single.exact,
				title: 'Представитель',
				component: LoanSellApplication.Single,
			},
			{
				path: url.accounting.invest.path,
				exact: url.accounting.invest.exact,
				title: 'Бухгалтерия',
				component: Accounting.Invest,
			},
			{
				path: url.accounting.payments.path,
				exact: url.accounting.payments.exact,
				title: 'Бухгалтерия',
				component: Accounting.Payments,
			},
			{
				path: url.accounting.documents.path,
				exact: url.accounting.documents.exact,
				title: 'Бухгалтерия',
				component: Accounting.Documents,
			},
			{
				path: url.accounting.bonuses.path,
				exact: url.accounting.bonuses.exact,
				title: 'Бухгалтерия',
				component: Accounting.Bonuses,
			},
		],
	},
]
