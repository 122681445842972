export default {
	home: {
		path: '/',
		exact: true,
	},

	login: {
		path: '/login',
		exact: true,
	},

	// Лиды
	newLead: {
		path: '/new_lead',
		exact: true,
	},
	leads: {
		path: '/leads',
		exact: true,
	},
	lead: {
		path: (id = ':id') => `/lead/${id}`,
		exact: true,
	},

	investmentLead: {
		list: {
			path: '/investment-leads',
			exact: true,
		},
		single: {
			path: (id = ':id') => `/investment-leads/${id}`,
			exact: true,
		},
	},

	// Заявки
	newApplication: {
		path: '/new_application',
		exact: true,
	},
	applications: {
		path: '/applications',
		exact: true,
	},
	application: {
		exact: true,
		path: (id = ':id') => `/application/${id}`,
	},

	// Займы
	loans: {
		path: '/loans',
		exact: true,
	},
	loan: {
		path: (id = ':id') => `/loan/${id}`,
		exact: true,
	},

	// Доставка
	delivery: {
		list: {
			path: '/deliveries',
			exact: true,
		},
		single: {
			path: (id = ':id') => `/deliveries/${id}`,
			exact: true,
		},
		create: {
			path: '/deliveries/create',
			exact: true,
		},
	},

	// Регистрация в росреестре
	rosreestrRegistration: {
		list: {
			path: '/rosreestr-registrations',
			exact: true,
		},
		single: {
			path: (id = ':id') => `/rosreestr-registrations/${id}`,
			exact: true,
		},
	},

	// Участники
	participants: {
		path: '/participants',
		exact: true,
	},
	participantCreate: {
		path: '/participants/create',
		exact: true,
	},
	// -- Физ лица
	person: {
		exact: true,
		path: (id = ':person_id') => `/person/${id}`,
	},
	personOut: {
		exact: true,
		path: (
			path = '(application|loan|lead|facility|organization|entrepreneur|deposit|delivery|rosreestr-registrations)',
			id = ':id',
			person_id = ':person_id'
		) => `/${path}/${id}/person/${person_id}`,
	},
	// -- Юр лица
	organization: {
		exact: true,
		path: (id = ':org_id') => `/organization/${id}`,
	},
	organizationOut: {
		exact: true,
		path: (
			path = '(application|loan|lead|facility|person|entrepreneur|organization|delivery|rosreestr-registrations)',
			id = ':id',
			org_id = ':org_id'
		) => `/${path}/${id}/organization/${org_id}`,
	},
	// -- ИП
	entrepreneur: {
		exact: true,
		path: (id = ':ent_id') => `/entrepreneur/${id}`,
	},
	entrepreneurOut: {
		exact: true,
		path: (
			path = '(application|loan|facility|organization|person|delivery)',
			id = ':id',
			ent_id = ':ent_id'
		) => `/${path}/${id}/entrepreneur/${ent_id}`,
	},

	// Обьекты
	facilities: {
		path: '/facilities',
		exact: true,
	},
	facility: {
		path: (id = ':obj_id') => `/facility/${id}`,
		exact: true,
	},
	facilityOut: {
		exact: true,
		path: (path = '(application|loan|lead)', id = ':id', obj_id = ':obj_id') =>
			`/${path}/${id}/facility/${obj_id}`,
	},

	assessment: {
		path: '/assessment',
		exact: true,
	},
	calculator: {
		path: '/calculator',
		exact: true,
	},

	// Сбережения
	deposit: {
		single: {
			path: (id = ':id') => `/deposit/${id}`,
			exact: true,
		},
		list: {
			path: '/deposits',
			exact: true,
		},
		create: {
			exact: true,
			path: '/deposits/create',
		},
	},

	// Партнеры
	partner: {
		single: {
			path: (id = ':id') => `/partners/${id}`,
			exact: true,
		},
		list: {
			path: '/partners',
			exact: true,
		},
	},

	yandex: {
		direct: {
			path: '/yandex-direct',
			exact: true,
		},
	},

	// Представители
	representative: {
		single: {
			path: (id = ':id') => `/representatives/${id}`,
			exact: true,
		},
		list: {
			path: '/representatives',
			exact: true,
		},
		create: {
			path: '/representative/create',
			exact: true,
		},
	},

	// Представители
	loanSellApplications: {
		single: {
			path: (id = ':id') => `/loan-sell-applications/${id}`,
			exact: true,
		},
		list: {
			path: '/loan-sell-applications',
			exact: true,
		},
	},

	// Бухгалтерия
	accounting: {
		invest: {
			path: '/accounting/invest',
			exact: true,
		},
		payments: {
			path: '/accounting/payments',
			exact: true,
		},
		bonuses: {
			path: '/accounting/bonuses',
			exact: true,
		},
		documents: {
			path: '/accounting/documents',
			exact: true,
		},
	},
} as const
