import { Component } from 'react'
import outsideClick from 'react-click-outside'
import { DateRange, DateRangePicker } from 'react-date-range'
import cx from 'clsx'
import {
	addDays,
	addMonths,
	endOfDay,
	endOfMonth,
	endOfWeek,
	isSameDay,
	startOfDay,
	startOfMonth,
	startOfWeek,
} from 'date-fns/esm'
import { ru } from 'date-fns/esm/locale'
import moment from 'moment'
import PropTypes from 'prop-types'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
}

const staticRangeHandler = {
	range: {},
	isSelected(range) {
		const definedRange = this.range()
		return (
			isSameDay(range.startDate, definedRange.startDate) &&
			isSameDay(range.endDate, definedRange.endDate)
		)
	},
}

export function createStaticRanges(ranges) {
	return ranges.map((range) => ({ ...staticRangeHandler, ...range }))
}

export const defaultStaticRanges = createStaticRanges([
	{
		label: 'Сегодня',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
			key: 'picker',
		}),
	},
	{
		label: 'Вчера',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
			key: 'picker',
		}),
	},
	{
		label: 'Текущая неделя',
		range: () => ({
			startDate: moment(defineds.startOfWeek).add(1, 'days')._d,
			endDate: moment(defineds.endOfWeek).add(1, 'days')._d,
			key: 'picker',
		}),
	},
	{
		label: 'Прошлая неделя',
		range: () => ({
			startDate: moment(defineds.startOfLastWeek).add(1, 'days')._d,
			endDate: moment(defineds.endOfLastWeek).add(1, 'days')._d,
			key: 'picker',
		}),
	},
	{
		label: 'Текущий месяц',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
			key: 'picker',
		}),
	},
	{
		label: 'Прошлый месяц',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
			key: 'picker',
		}),
	},
])

class DatePicker extends Component {
	static propTypes = {
		className: PropTypes.string,
		ranges: PropTypes.object.isRequired,

		onChange: PropTypes.func.isRequired,
	}

	state = {
		isOpen: false,
	}

	handleSelect = (ranges) => {
		const { onChange } = this.props

		const dates = {
			...ranges,
			selection: {
				...ranges.selection,
				endDate:
					ranges.selection.key === 'selection'
						? moment(ranges.selection.endDate).add({ days: 1 })._d
						: ranges.selection.endDate,
			},
		}

		onChange(dates)
	}

	handleClickOutside() {
		this.setState({ isOpen: false })
	}

	render() {
		const { ranges, className } = this.props

		const sd = { startDate: moment.isDate(ranges.minDate) && ranges.minDate }
		const ed = {
			endDate:
				moment.isDate(ranges.maxDate) && moment(ranges.maxDate).subtract({ milliseconds: 1 })._d,
		}

		const selectionRange = {
			...(sd.startDate ? sd : {}),
			...(ed.endDate ? ed : {}),
			key: 'selection',
		}

		const picker = (
			<DateRangePicker
				locale={ru}
				inputRanges={[{}, {}]}
				showDateDisplay={false}
				rangeColors={['#00A1DC']}
				ranges={[selectionRange]}
				onChange={this.handleSelect}
				className='date-picker-block'
				staticRanges={defaultStaticRanges}
			/>
		)

		return (
			<div
				className={cx('date-picker', {
					[className]: !!className,
				})}
			>
				<div
					className='date-picker-input'
					onClick={() => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))}
				>
					<span>
						{ranges.minDate || ranges.maxDate
							? `${moment(selectionRange.startDate).format('D MMM YY')} — ${moment(
									selectionRange.endDate
							  ).format('D MMM YY')}`
							: 'Выберите дату'}
					</span>
				</div>
				{this.state.isOpen && picker}
			</div>
		)
	}
}

export default outsideClick(DatePicker)
